$primaryBackground: #f4f4f9;
$primaryBlue: #5a5e9d;
$textColor: #545252;
$primaryPink: #f4b3d1;
$primaryColor: #060a28;
$primaryGreen: #3fa15e;
$primaryLightGreen: #20ca56;
$primaryLightBlue: #a6acc3;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin: 2rem 1rem;
}

.exportExcelButton {
  background-color: $primaryGreen !important;
  color: white;
  width: 17rem;
  margin-right: 1rem;
}

.dFlex {
  display: flex;
}

.tableTopActions {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid $primaryBackground;
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.item_details {
  width: 70%;
  margin: 0.3rem auto 0 auto;
  padding: 1rem;
}

.item_details_text {
  color: black !important;
  font-weight: bold !important;
  margin-bottom: 0.3rem !important;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.statsCardContainer {
  width: 98%;
  margin: 0 auto 1.5rem auto;
}

.dashboardCard {
  background-color: white;
  border-radius: 0.7rem;
  height: 12.5rem;
  overflow: hidden;
  padding: 1rem;
}

.dashboardCard_top {
  display: flex;
  justify-content: space-between;
}

.dashboardCard_label {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.dashboardCard_metric {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardCard_metric_value {
  font-size: 2rem !important;
  font-weight: bold !important;
}

.dashboardCard_text_metric {
  display: flex;
  align-items: center;
}

.dashboardCard_text {
  color: $textColor !important;
  margin-left: 0.3rem !important;
}

.notifications {
  background-color: white;
  border-radius: 0.7rem;
  height: 25rem;
  overflow-y: scroll;
}

.chart_container {
  background-color: white;
  border-radius: 0.7rem;
  height: 27rem;
}

.formDocument {
  background-color: white;
  width: 80%;
  margin: 1.8rem auto;
  border: 2px dashed grey;
  border-radius: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem !important;
}

.dashboard_skeleton_card {
  height: 13.3rem !important;
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dashboard_skeleton_cards {
  display: flex;
  justify-content: space-between;
}

.newPurchaseContainer {
  height: 90vh;
  width: 80vw;
}

.paper {
  min-width: 70vw !important;
  min-height: 85vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmButton {
  background-color: $primaryGreen !important;
  margin: 3rem 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.pageNav {
  display: flex;
  flex-shrink: 0;
  justify-content: start;
}

.pageNav .MuiListItem-root {
  padding-left: 4px !important;
  padding-right: 4px !important;
  width: 30px !important;
}

.listItemSelected {
  border-bottom: 2px solid $primaryLightGreen;
  background-color: $primaryBackground !important;

  .listItemText {
    color: $primaryLightGreen;
  }
}

.root {
  font-size: 0.8rem;
  color: $textColor;
  width: auto !important;
  padding-bottom: 3px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin: 0 0.5rem;

  &:hover {
    color: #3fa15e;
  }
  &:selected {
    &:hover {
    }

    .listItemText {
      color: $primaryLightBlue;
    }
  }
}
