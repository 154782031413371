.notification_header {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification_heading {
    color: #060a28 !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    margin-right: 1rem !important;
}

.notification_item {
    border-bottom: 1px solid #f4f4f9;
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.notification_time {
    align-self: flex-end !important;
    font-size: 0.8rem !important;
}

.notification_message {
    font-size: 0.8rem !important;
    color: #6b6a6a !important;
}

.notification_bottom_content {
    display: flex;
    justify-content: space-between;
}