$primaryBackground: #f4f4f9;
$primaryBlue: #5a5e9d;
$primaryPink: #f4b3d1;
$primaryColor: #060a28;
$textColor: #545252;
$primaryGreen: #3fa15e;
$primaryLightBlue: #a6acc3;
$primaryGrey: #f8f8f8;
$deleteRed2: #f9320d;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}

.buttonContainer {
  width: 95%;
}

.sideNav {
  background-color: $primaryColor;
  height: 100vh;
  padding-bottom: 4rem;
  overflow-y: scroll;
}

.main {
  background-color: $primaryBackground;
  height: 100vh;
  padding-right: 2rem !important;
  overflow-y: scroll;
}

.logo_container {
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none;
  display: flex;
  color: textColor;
  line-height: 0.4rem;
}

.listItem {
  padding-top: "3px";
  padding-bottom: "3px";
  color: $textColor;
}

.listItemIcon {
  min-width: "2rem";
}

.listItemText {
  font-size: 0.9rem !important;
  line-height: 0.2 !important;
  color: $primaryBlue;
  &:hover {
    color: $primaryLightBlue;
  }
}

.navMenuSection {
  margin: 1rem 0 0 1.5rem !important;
}

.listItemSelected {
  color: $primaryLightBlue;

  .listItemText {
    color: $primaryLightBlue;
  }
}

.root {
  font-size: 0.8rem;
  &:hover {
  }
  &:selected {
    &:hover {
    }

    .listItemText {
      color: $primaryLightBlue;
    }
  }
}

.table {
  // minWidth: 530;
  width: 100%;
}

.customTable {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  padding: 1rem;
  .MuiTableCell-sizeSmall {
    padding: 6px 0px 6px 16px; // <-- arbitrary value
  }
}

.tableRow {
  .MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.noDataContainer {
  height: 70vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataSvg {
  height: 10rem;
  width: 10rem;
  margin-bottom: 2rem;
}

.genericForm {
  min-width: 55vw !important;
  min-height: 65vh;
}

.genericForm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 1rem 0.5rem 1rem !important;
}

.genericForm_column {
  margin: 0 2rem;
  min-height: 20rem;
}

.genericFormVertical {
  min-width: 35vw !important;
  min-height: 75vh;
}

.genericFormVertical_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.genericFormVertical_column {
  margin: 0 2rem;
}

.form_title {
  margin-top: 1.5rem !important;
  font-weight: bold !important;
  font-size: 1.4rem !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

.form_label {
  font-size: 0.85rem !important;
  margin: 0.2rem 0.2rem 0.2rem 0.4rem !important;
}

.display_flex {
  display: flex;
  align-items: center;
}

.form_action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formSelect {
  border: 1px solid #c0bfbf;
  border-radius: 5px;
  width: 94%;
  font-size: 0.97rem !important;
  padding: 0.5rem 0 0.5rem 1rem !important;
  color: textColor;
  font-weight: bold;
  background-color: $primaryGrey;
  color: $textColor;
}

.form_select_skeleton {
  height: 4rem !important;
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
  border: 1px solid gray !important;
}

.form_input {
  border: 1px solid $primaryGrey;
  border-radius: 5px;
  width: 100%;
  color: textColor;
  font-size: 0.8rem !important;
  font-weight: bold;
  background-color: $primaryGrey;
  color: $textColor;
}

.forms_input_horizontal {
  margin: 0.5rem 1.2rem;
  width: 43%;
  display: inline-block;
}

.forms_input_vertical {
  margin: 0.5rem 1.2rem 0.5rem 0;
  width: 90%;
  display: inline-block;
}

.textField_input {
  font-size: 0.97rem !important;
  padding: 0.5rem 0 0.5rem 1rem !important;

  // &:hover: {
  //   background-color: red;
  // }
}

.closeButton {
  cursor: pointer;
  background-color: $primaryGrey;
  padding: 0.4rem;
  border-radius: 6px;
  &:hover {
    background-color: $primaryGrey;
  }
}

.confirmButton {
  background-color: $primaryGreen !important;
  margin: 3rem 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.form_confirmButton {
  background-color: $primaryGreen !important;
  margin: 1.5rem 0.5rem 1rem 0.5rem !important;
  padding: 0.7rem 2rem !important;
  width: 30%;
  border-radius: 1.2rem;
}

.form_cancelButton {
  background-color: white !important;
  margin: 1.5rem 0.5rem 1rem 0.5rem !important;
  padding: 0.7rem 2rem !important;
  width: 30%;
  border-radius: 1.2rem;
  color: $primaryGreen !important;
  border: 1px solid $primaryGreen !important;
}

.dateInput input {
  border: 1px solid $primaryGrey !important;
  border-radius: 5px !important;
  width: 100% !important;
  padding: 8px 15px !important;
  color: textColor;
  font-size: 0.8rem !important;
  font-weight: bold !important;
  background-color: $primaryGrey !important;
  color: $textColor !important;
}

.dateInputRoot {
  width: 100% !important;
}

.loadingContainer {
  height: 23rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  height: 3.5rem;
  width: 100%;
  margin: 0.1rem 0;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmModal {
  min-width: 30vw;
  min-height: 65vh;
}

.confirmModal_Confirm {
  margin: 1.5rem 0.5rem 1rem 0.5rem !important;
  padding: 0.7rem 2rem !important;
  width: 30%;
  background-color: $primaryBlue !important;
  text-transform: none;
  &:hover {
    background-color: $primaryBlue !important;
  }
}

.confirmModal_Cancel {
  background-color: white !important;
  width: 30%;
  color: $primaryBlue !important;
  border: 1px solid $primaryBlue !important;
  margin: 1.5rem 0.5rem 1rem 0.5rem !important;
  padding: 0.7rem 2rem !important;
  width: 30%;
  text-transform: none;
  &:hover {
    background-color: white !important;
  }
}

.confirmModal_title {
  font-size: 1.4rem !important;
  font-weight: bold !important;
  color: $textColor;
}

.confirmModal_text {
  font-size: 0.9rem;
  text-align: center;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.item_detail {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: white;
}

.search {
  position: relative;
  border-radius: 0.6rem;
  background-color: white;
  margin-right: 1rem;
  width: 100%;
}

.searchIcon {
  padding-left: 1rem;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
}
.inputInput {
  padding: 10px !important;
  border-radius: 0px !important;
  padding-left: 3rem !important;
}

.purchasesReceipt {
  border: 1px solid $primaryGrey;
  height: 25rem;
  width: 80%;
  padding-top: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  box-shadow: 2px 4px 5px $primaryGrey;
  overflow-y: scroll;
}

.purchasesReceipt_header {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0 0;
}

.receipt_text {
  font-size: 0.8rem !important;
}

.receipt_items_text {
  font-size: 0.7rem !important;
  font-weight: bold !important;
}

.centerText {
  text-align: center;
}

.receiptTotal {
  text-align: right;
  color: $primaryColor;
  font-size: 0.8rem !important;
  font-weight: bolder;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.receipt_textStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25;
  color: $textColor;
}

.receipt_logo {
  max-width: 5rem;
  max-width: 5rem;
}

.receipt_table {
  margin: 0px 0.7rem;
  padding: 0 5px;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: 900;
}

.receipt_title {
  text-align: center;
  color: black;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;
}
.receiptItem {
  border-bottom: 1px dashed black;
  margin-top: 10px;
  margin-bottom: 3px;
}

.receiptActions {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

.receipt_save_btn {
  background: $primaryGreen !important;
  margin-left: 2rem !important;
}

.receipt_print_btn {
  background-color: white !important;
  color: $primaryGreen !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  border: 2px solid grey !important;
  border-radius: 0.5rem !important;
  &:hover {
    background-color: $primaryGreen !important;
    color: white !important;
    border: 2px solid $primaryGreen !important;
  }
}
