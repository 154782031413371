@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.page_nav {
    display: flex;
    flex-shrink: 0;
    justify-content: start;
    width: 30px !important;
}

.page_nav .MuiList-root {
    width: 30px !important;
}


.custom-form-item {
    /* Custom styles */
    @apply px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500;
}

.signup_form .ant-form-item {
    margin-bottom: 2rem;
    width: 100%;
}

.my_form {
    padding-left: 1rem;
    padding-right: 1rem;
}

.my_horizontal_form {
    padding-left: 1rem;
    padding-right: 1rem;
}

.ant-form-item {
    width: 100%;
}

.my_form .ant-form-item {
    margin-bottom: 1rem;
}

.my_horizontal_form .ant-form-item {
    margin-bottom: 0rem;
}

.my_horizontal_form .ant-form-item-label {
    padding: 0 0 1px 5px;
    font-size: 10px !important;
}

.my_form .ant-form-item-label {
    padding: 0 0 3px 5px;
    font-size: 12px !important;
}

.ant-input {
    background-color: #f8f8f8;
}

.ant-select {
    background-color: #f8f8f8 !important;
}

.ant-picker {
    width: 100%;
    background-color: #f8f8f8;
}

.ant-form-item-control-input {
    background-color: #f8f8f8;
}

.ant-input-number {
    width: 100%;
    background-color: #f8f8f8;
}

.ant-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ant-col-16 {
    max-width: 100% !important;
}

.ant-col {
    max-width: 100% !important;
}

.ant-select-dropdown {
    z-index: 9999;
}

.ant-picker-dropdown {
    z-index: 9999;
}

.expense_card div:last-child {
    border-right: none !important;
}